import axios from 'axios'

const { REACT_APP_API_URL } = process.env

export const fetchWallrides = async walletId => {
	return axios
		.get(`${REACT_APP_API_URL}/list/${walletId}`)
		.then(response => response.data)
}

export const generateNFT = async (mintId, transactionSignature, dimensions) => {
	return axios
		.post(`${REACT_APP_API_URL}/generate`, {
			mintId,
			dimensions,
			transactionSignature,
		})
		.then(response => response.data)
}

export const fetchWalltideFiles = async mintId => {
	return axios
		.get(`${REACT_APP_API_URL}/${mintId}`)
		.then(response => response.data)
}
