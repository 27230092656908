import borsh from './borsh'

import { programIds, findProgramAddress } from './programIds'
import { toPublicKey } from './ids'
export const METADATA_PREFIX = 'metadata'
export const EDITION = 'edition'
export const RESERVATION = 'reservation'

export const MAX_NAME_LENGTH = 32

export const MAX_SYMBOL_LENGTH = 10

export const MAX_URI_LENGTH = 200

export const MAX_CREATOR_LIMIT = 5

export const MAX_CREATOR_LEN = 32 + 1 + 1
export const MAX_METADATA_LEN =
	1 +
	32 +
	32 +
	MAX_NAME_LENGTH +
	MAX_SYMBOL_LENGTH +
	MAX_URI_LENGTH +
	MAX_CREATOR_LIMIT * MAX_CREATOR_LEN +
	2 +
	1 +
	1 +
	198

export const MAX_EDITION_LEN = 1 + 32 + 8 + 200

export const EDITION_MARKER_BIT_SIZE = 248

export const MetadataKey = {
	Uninitialized: 0,
	MetadataV1: 4,
	EditionV1: 1,
	MasterEditionV1: 2,
	MasterEditionV2: 6,
	EditionMarker: 7,
}

export const MetadataCategory = {
	Audio: 'audio',
	Video: 'video',
	Image: 'image',
	VR: 'vr',
}

export class MasterEditionV1 {
	key
	supply
	maxSupply
	/// Can be used to mint tokens that give one-time permission to mint a single limited edition.
	printingMint
	/// If you don't know how many printing tokens you are going to need, but you do know
	/// you are going to need some amount in the future, you can use a token from this mint.
	/// Coming back to token metadata with one of these tokens allows you to mint (one time)
	/// any number of printing tokens you want. This is used for instance by Auction Manager
	/// with participation NFTs, where we dont know how many people will bid and need participation
	/// printing tokens to redeem, so we give it ONE of these tokens to use after the auction is over,
	/// because when the auction begins we just dont know how many printing tokens we will need,
	/// but at the end we will. At the end it then burns this token with token-metadata to
	/// get the printing tokens it needs to give to bidders. Each bidder then redeems a printing token
	/// to get their limited editions.
	oneTimePrintingAuthorizationMint

	constructor(args) {
		this.key = MetadataKey.MasterEditionV1
		this.supply = args.supply
		this.maxSupply = args.maxSupply
		this.printingMint = args.printingMint
		this.oneTimePrintingAuthorizationMint =
			args.oneTimePrintingAuthorizationMint
	}
}

export class MasterEditionV2 {
	key
	supply
	maxSupply

	constructor(args) {
		this.key = MetadataKey.MasterEditionV2
		this.supply = args.supply
		this.maxSupply = args.maxSupply
	}
}

export class EditionMarker {
	key
	ledger

	constructor(args) {
		this.key = MetadataKey.EditionMarker
		this.ledger = args.ledger
	}

	editionTaken(edition) {
		const editionOffset = edition % EDITION_MARKER_BIT_SIZE
		const indexOffset = Math.floor(editionOffset / 8)

		if (indexOffset > 30) {
			throw Error('bad index for edition')
		}

		const positionInBitsetFromRight = 7 - (editionOffset % 8)

		const mask = Math.pow(2, positionInBitsetFromRight)

		const appliedMask = this.ledger[indexOffset] & mask

		return appliedMask != 0
	}
}

export class Edition {
	key
	/// Points at MasterEdition struct
	parent
	/// Starting at 0 for master record, this is incremented for each edition minted.
	edition

	constructor(args) {
		this.key = MetadataKey.EditionV1
		this.parent = args.parent
		this.edition = args.edition
	}
}

export class Creator {
	address
	verified
	share

	constructor(args) {
		this.address = args.address
		this.verified = args.verified
		this.share = args.share
	}
}

export class Data {
	name
	symbol
	uri
	sellerFeeBasisPoints
	creators
	constructor(args) {
		this.name = args.name
		this.symbol = args.symbol
		this.uri = args.uri
		this.sellerFeeBasisPoints = args.sellerFeeBasisPoints
		this.creators = args.creators
	}
}

export class Metadata {
	key
	updateAuthority
	mint
	data
	primarySaleHappened
	isMutable
	editionNonce

	// set lazy
	masterEdition
	edition

	constructor(args) {
		this.key = MetadataKey.MetadataV1
		this.updateAuthority = args.updateAuthority
		this.mint = args.mint
		this.data = args.data
		this.primarySaleHappened = args.primarySaleHappened
		this.isMutable = args.isMutable
		this.editionNonce = args.editionNonce
	}

	async init() {
		const edition = await getEdition(this.mint)
		this.edition = edition
		this.masterEdition = edition
	}
}

class CreateMetadataArgs {
	instruction = 0
	data
	isMutable

	constructor(args) {
		this.data = args.data
		this.isMutable = args.isMutable
	}
}

class UpdateMetadataArgs {
	instruction = 1
	data
	// Not used by this app, just required for instruction
	updateAuthority
	primarySaleHappened
	constructor(args) {
		this.data = args.data ? args.data : null
		this.updateAuthority = args.updateAuthority ? args.updateAuthority : null
		this.primarySaleHappened = args.primarySaleHappened
	}
}

class CreateMasterEditionArgs {
	instruction = 10
	maxSupply
	constructor(args) {
		this.maxSupply = args.maxSupply
	}
}

class MintPrintingTokensArgs {
	instruction = 9
	supply

	constructor(args) {
		this.supply = args.supply
	}
}

export const METADATA_SCHEMA = new Map([
	[
		CreateMetadataArgs,
		{
			kind: 'struct',
			fields: [
				['instruction', 'u8'],
				['data', Data],
				['isMutable', 'u8'], // bool
			],
		},
	],
	[
		UpdateMetadataArgs,
		{
			kind: 'struct',
			fields: [
				['instruction', 'u8'],
				['data', { kind: 'option', type: Data }],
				['updateAuthority', { kind: 'option', type: 'pubkeyAsString' }],
				['primarySaleHappened', { kind: 'option', type: 'u8' }],
			],
		},
	],

	[
		CreateMasterEditionArgs,
		{
			kind: 'struct',
			fields: [
				['instruction', 'u8'],
				['maxSupply', { kind: 'option', type: 'u64' }],
			],
		},
	],
	[
		MintPrintingTokensArgs,
		{
			kind: 'struct',
			fields: [
				['instruction', 'u8'],
				['supply', 'u64'],
			],
		},
	],
	[
		MasterEditionV1,
		{
			kind: 'struct',
			fields: [
				['key', 'u8'],
				['supply', 'u64'],
				['maxSupply', { kind: 'option', type: 'u64' }],
				['printingMint', 'pubkeyAsString'],
				['oneTimePrintingAuthorizationMint', 'pubkeyAsString'],
			],
		},
	],
	[
		MasterEditionV2,
		{
			kind: 'struct',
			fields: [
				['key', 'u8'],
				['supply', 'u64'],
				['maxSupply', { kind: 'option', type: 'u64' }],
			],
		},
	],
	[
		Edition,
		{
			kind: 'struct',
			fields: [
				['key', 'u8'],
				['parent', 'pubkeyAsString'],
				['edition', 'u64'],
			],
		},
	],
	[
		Data,
		{
			kind: 'struct',
			fields: [
				['name', 'string'],
				['symbol', 'string'],
				['uri', 'string'],
				['sellerFeeBasisPoints', 'u16'],
				['creators', { kind: 'option', type: [Creator] }],
			],
		},
	],
	[
		Creator,
		{
			kind: 'struct',
			fields: [
				['address', 'pubkeyAsString'],
				['verified', 'u8'],
				['share', 'u8'],
			],
		},
	],
	[
		Metadata,
		{
			kind: 'struct',
			fields: [
				['key', 'u8'],
				['updateAuthority', 'pubkeyAsString'],
				['mint', 'pubkeyAsString'],
				['data', Data],
				['primarySaleHappened', 'u8'], // bool
				['isMutable', 'u8'], // bool
			],
		},
	],
	[
		EditionMarker,
		{
			kind: 'struct',
			fields: [
				['key', 'u8'],
				['ledger', [31]],
			],
		},
	],
])

// eslint-disable-next-line no-control-regex
const METADATA_REPLACE = new RegExp('\u0000', 'g')

export const decodeMetadata = buffer => {
	const metadata = borsh.deserializeUnchecked(METADATA_SCHEMA, Metadata, buffer)

	metadata.data.name = metadata.data.name.replace(METADATA_REPLACE, '')
	metadata.data.uri = metadata.data.uri.replace(METADATA_REPLACE, '')
	metadata.data.symbol = metadata.data.symbol.replace(METADATA_REPLACE, '')

	return metadata
}

export async function getEdition(tokenMint) {
	const PROGRAM_IDS = programIds()

	return (
		await findProgramAddress(
			[
				Buffer.from(METADATA_PREFIX),
				toPublicKey(PROGRAM_IDS.metadata).toBuffer(),
				toPublicKey(tokenMint).toBuffer(),
				Buffer.from(EDITION),
			],
			toPublicKey(PROGRAM_IDS.metadata)
		)
	)[0]
}
