function ConnectWalletModal(props) {
	return (
		<div className="connectwalletmodal">
			<div className="modal-content">
				<div className="close-button" onClick={props.onClose} />

				<span className="title">Connect Wallet</span>

				<div className="wallets-wrapper">
					<div className="wallet-button" onClick={props.onConnect}>
						Phantom Wallet
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConnectWalletModal
