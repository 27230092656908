function TextField(props) {
	const labelText = props.required ? `${props.label}*` : props.label

	return (
		<div className="textfield">
			<span className="label">{labelText}</span>
			<div className="input-wrapper">
				<input value={props.value} onChange={props.onChange} />
				<span>{props.right}</span>
			</div>
		</div>
	)
}

export default TextField
