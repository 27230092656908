import { useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import { useWindowSize } from '../../hooks/useWindowSize'

import WallrideLogo from '../../assets/WallrideLogo'

import TryARExpModal from './components/TryARExpModal'
import ConnectWalletModal from './components/ConnectWalletModal'

import { connectPhantomWallet } from '../../utils/phantom'

const renderSocialLink = (title, href) => (
	<div className="social-link-wrapper">
		<a href={href} target="_blank" className="social-link">
			{title}
		</a>
	</div>
)

const renderLink = (title, to) => (
	<div className="link-button" onClick={() => window.open(to, '_blank')}>
		{title}
	</div>
)

function HomePage() {
	const history = useHistory()
	const { width } = useWindowSize()
	const [activeTab, setActiveTab] = useState(0)
	const [arExpModalOpen, setARExpModalOpen] = useState(false)
	const [connectWalletModalOpen, setConnectWalletModalOpen] = useState(false)

	const handleLaunchApp = useCallback(() => {
		setConnectWalletModalOpen(true)
	}, [setConnectWalletModalOpen])

	const handleConnectWallet = useCallback(async () => {
		setConnectWalletModalOpen(false)

		try {
			await connectPhantomWallet()
			history.push('/wallet')
		} catch (e) {
			console.error('Error connecting to Phantom Wallet', e)
		}
	}, [history])

	if (width < 900) {
		return (
			<div className="unsupported-screen-size-wrapper">
				<div className="header">
					<WallrideLogo fill="white" className="wallride-logo" />
				</div>
				<div className="page-content">
					<div className="description">
						Wallride BETA bridges the gap between legacy art & NFTs, providing
						augmented reality exp's tethered to your Solana NFTs.
						<br />
						<br />
						The wallrides you create are viewable on iPhone, Android & web. LFG!
					</div>
				</div>

				<div className="desktop-message">
					To get started, visit wallride.io on your desktop computer.
				</div>
			</div>
		)
	}

	return (
		<div className="homepage">
			{arExpModalOpen && (
				<TryARExpModal onClose={() => setARExpModalOpen(false)} />
			)}

			{connectWalletModalOpen && (
				<ConnectWalletModal
					onConnect={handleConnectWallet}
					onClose={() => setConnectWalletModalOpen(false)}
				/>
			)}
			<div className="header">
				<div className="wallride-title-info">
					<WallrideLogo fill="white" className="wallride-logo" />
					<span className="wallride-title">Wallride BETA: AR for NFTs</span>
				</div>

				<div onClick={handleLaunchApp} className="launch-button">
					Launch App
				</div>
			</div>

			<div className="homepage-content">
				<div className="wallride-info-wrapper">
					<div className="wallride-demo-container">
						<div className="wallride-text-container">
							Wallride bridges the gap between legacy art & NFTs, providing
							augmented reality exp's tethered to your Solana NFTs.
							<br />
							<br />
							The wallrides you create are viewable on iOS and Android. LFG!
						</div>

						<div
							className="try-experience-button"
							onClick={() => setARExpModalOpen(true)}
						>
							Try AR Experience
						</div>
					</div>

					<div className="sample-wallride-wrapper">
						<model-viewer
							ar
							auto-rotate
							camera-controls
							alt="Sample Wallride"
							environment-image="neutral"
							ar-modes="webxr scene-viewer quick-look"
							src="https://artiseen-dev.s3.us-east-2.amazonaws.com/usdz/wallride-samples/3LTbPJmXZNigVXYHWp968JGAXq4widU1sYyxUZkD21nn.gltf"
						></model-viewer>
					</div>
				</div>

				<div className="cool-links-wrapper">
					<span className="title">COOL LINKS!</span>

					<div className="links-wrapper">
						{renderLink(
							'Litepaper',
							'https://drive.google.com/file/d/14KoIjZa4J7xsfjhITlzgm0uXpAAoXhdJ/view'
						)}
						{renderLink(
							'Roadmap',
							'https://drive.google.com/file/d/12OpaVOnopgOpkKvjKYokgR7NSXah-yS1/view'
						)}
						{/* {renderLink('Android App')}
						<div className="link-button link-button--disabled">
							iOS App - WIP
						</div> */}
					</div>
				</div>

				<div className="homepage-footer">
					<div className="social-wrapper">
						{renderSocialLink('Twitter', 'https://twitter.com/wallride_app')}
						{renderSocialLink('Discord', 'https://discord.gg/YTshTbc2Aj')}
						{renderSocialLink('Insta', 'https://www.instagram.com/wallride.io')}
						{renderSocialLink('TikTok', 'https://www.tiktok.com/@wallride_app')}
					</div>

					<div className="credits-wrapper">
						Created by
						<br />
						<br />
						Wallride, Inc
					</div>
				</div>
			</div>
		</div>
	)
}

export default HomePage
