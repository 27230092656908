import { useMemo, useState, useCallback } from 'react'
import QRCode from 'react-qr-code'
// import classnames from 'classnames'

// import Switch from '../../../../../components/Switch'
// import Checkbox from '../../../../../components/Checkbox'

function SwitcherStep(props) {
	// const [arMode, setARMode] = useState('web')
	// const [walletConnected, setWalletConnected] = useState(false)

	// const handleARModeChange = useCallback(
	// 	value => {
	// 		setARMode(value ? 'native' : 'web')
	// 	},
	// 	[setARMode]
	// )

	const gltfFileURL = useMemo(() => {
		return `${window.location.origin}/wallride-viewer/${props.wallrideId}`
	}, [props.wallrideId])

	return (
		<>
			<span className="title">VIEW AR EXP</span>
			<span className="subtitle">on your wall</span>

			<div className="content-wrapper">
				{/* <div className="switch-wrapper">
					<span>Web AR</span>
					<Switch on={arMode === 'native'} onChange={handleARModeChange} />
					<span>Native</span>
				</div> */}

				{/* <div
					className={classnames('qrcodes-wrapper', {
						'qrcodes-wrapper--hidden': arMode !== 'web',
					})}
				> */}
				<div className="qrcodes-wrapper">
					<div className="qrcode">
						<span className="label">iOS & Android</span>
						<QRCode value={gltfFileURL} />
					</div>
				</div>

				{/* {arMode === 'native' && (
					<div className="connect-app-wrapper">
						{!walletConnected && (
							<>
								<span className="title">Connect Native App</span>
								<div
									className="connect-button"
									onClick={() => props.onStepChange('download')}
								>
									Let's Go
								</div>
							</>
						)}

						<div className="wallet-connected-checkbox-wrapper">
							<Checkbox on={walletConnected} onChange={setWalletConnected} />
							<span>My wallet is connected</span>
						</div>

						{walletConnected && (
							<div className="wallet-connected-text">
								Any Wallride you create will appear in your app!. Also, if you
								trade or buy an NFT with a Wallride, it will show in your viewer
								app.
							</div>
						)}
					</div>
				)} */}
			</div>
		</>
	)
}

export default SwitcherStep
