import base58 from 'bs58'
import * as borsh from 'borsh'
import * as web3 from '@solana/web3.js'

borsh.BinaryReader.prototype.readPubkey = function () {
	const array = this.readFixedArray(32)
	return new web3.PublicKey(array)
}

borsh.BinaryWriter.prototype.writePubkey = function (value) {
	this.writeFixedArray(value.toBuffer())
}

borsh.BinaryReader.prototype.readPubkeyAsString = function () {
	const array = this.readFixedArray(32)
	return base58.encode(array)
}

borsh.BinaryWriter.prototype.writePubkeyAsString = function (value) {
	this.writeFixedArray(base58.decode(value))
}

export default borsh
