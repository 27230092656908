import { useMemo, useState, useEffect, useCallback } from 'react'

function AspectRatioImage(props) {
	const [imageDimensions, setImageDimensions] = useState()

	useEffect(() => {
		if (imageDimensions) setImageDimensions(undefined)
	}, [props.imageUrl])

	const onImageLoad = useCallback(
		e => {
			props.onLoad && props.onLoad(e)

			const dimensions = {
				width: e.target.naturalWidth,
				height: e.target.naturalHeight,
			}

			props.onImageDimensions && props.onImageDimensions(dimensions)
			setImageDimensions(dimensions)
		},
		[props, setImageDimensions]
	)

	const imageStyle = useMemo(() => {
		if (!imageDimensions) return undefined

		const maxWidth = props.maxWidth
		const maxHeight = props.maxHeight

		const aspectRatio = Math.min(
			maxWidth / imageDimensions.width,
			maxHeight / imageDimensions.height
		)
		return {
			width: imageDimensions.width * aspectRatio,
			height: imageDimensions.height * aspectRatio,
		}
	}, [imageDimensions, props.maxWidth, props.maxHeight])

	return (
		<div className="aspect-ratio-image">
			{!imageDimensions && (
				<img
					alt=""
					src={props.imageUrl}
					onLoad={onImageLoad}
					className="temporary-image"
				/>
			)}
			{imageDimensions && (
				<img
					style={imageStyle}
					src={props.imageUrl}
					className="real-image"
					alt={props.imageName || 'NTF'}
				/>
			)}
		</div>
	)
}

export default AspectRatioImage
