import { useMemo, useState, useEffect, createContext } from 'react'

export const WalletContext = createContext({
	nfts: [],
	connected: false,
	setNFTs: () => {},
	publicKey: undefined,
})

export const WalletContextProvider = ({ children }) => {
	const [nfts, setNFTs] = useState([])
	const [connected, setConnected] = useState(false)
	const [wallrides, setWallrides] = useState(undefined)
	const [publicKey, setPublicKey] = useState(undefined)

	useEffect(() => {
		if (window.solana) {
			window.solana.on('connect', () => {
				setConnected(true)
				setPublicKey(window.solana.publicKey.toString())
			})

			window.solana.on('disconnect', () => {
				setConnected(false)
			})
		}
	}, [])

	const contextValue = useMemo(
		() => ({
			nfts,
			setNFTs,
			connected,
			publicKey,
			wallrides,
			setWallrides,
		}),
		[nfts, setNFTs, connected, publicKey, wallrides, setWallrides]
	)

	return (
		<WalletContext.Provider value={contextValue}>
			{children}
		</WalletContext.Provider>
	)
}
