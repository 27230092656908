import QRCode from 'react-qr-code'

function DownloadAppStep(props) {
	return (
		<>
			<span className="title">1. Download</span>
			<span className="subtitle">Get Native App</span>

			<div className="content-wrapper">
				<div className="qrcodes-wrapper">
					<div className="qrcode">
						<span className="label">iOS</span>
						<QRCode size={150} value="caio.com" />
					</div>

					<div className="qrcode">
						<span className="label">Android</span>
						<QRCode size={150} value="caio.com" />
					</div>
				</div>

				<div
					className="next-button"
					onClick={() => props.onStepChange('connect')}
				>
					Next
				</div>
			</div>
		</>
	)
}

export default DownloadAppStep
