import { Route, Switch } from 'react-router-dom'

import NFTCreationPage from './NFTCreation'
import NFTSelectionPage from './NFTSelection'

import WalletConnectionBadge from '../../components/WalletConnectionBadge'

function NFTCreationRoutesFlow() {
	return (
		<div className="nftcreationflow">
			<WalletConnectionBadge />

			<Switch>
				<Route exact path="/wallet/create">
					<NFTSelectionPage />
				</Route>

				<Route exact path="/wallet/create/:mint">
					<NFTCreationPage />
				</Route>
			</Switch>
		</div>
	)
}

export default NFTCreationRoutesFlow
