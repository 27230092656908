function Header(props) {
	return (
		<div className="header">
			{props.leftAccessory === 'close' && (
				<div className="close-button" onClick={props.onLeftAccessoryClick} />
			)}

			{props.leftAccessory === 'back' && (
				<div className="back-button" onClick={props.onLeftAccessoryClick} />
			)}

			<div className="header-title">{props.title}</div>

			<div className="header-content">{props.children}</div>
		</div>
	)
}

export default Header
