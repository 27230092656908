import * as web3 from '@solana/web3.js'
import { useState, useEffect, useContext, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import classnames from 'classnames'

import { WalletContext } from '../../contexts/wallet'

import { generateNFT } from '../../api'

import Header from './components/Header'
import TextField from '../../components/TextField'
import AspectRatioImage from '../../components/AspectRatioImage'

import { getNFTMetadataCollection } from '../../utils/metadata/collection'

const WALLRIDE_FEE = 300000000 // 0.3 SOL

const MAX_DIMENSION = 50

function NFTCreationPage() {
	const history = useHistory()
	const { mint } = useParams()
	const { nfts } = useContext(WalletContext)
	const [loadingStatus, setLoadingStatus] = useState(undefined)
	const [imageDimensions, setImageDimensions] = useState()
	const [nftMetadataInfo, setNFTMetadataInfo] = useState(undefined)
	const [wallrideDimensions, setWallrideDimensions] = useState({
		depth: '',
		width: '',
		height: '',
	})

	useEffect(() => {
		if (!mint || !nfts) return handleGoBack()

		const nft = nfts.find(nft => nft.mint === mint)
		if (!nft) return handleGoBack()

		fetch(nft.data?.uri)
			.then(response => response.json())
			.then(data => {
				setNFTMetadataInfo(data)
			})
			.catch(e => {
				handleGoBack()
			})
	}, [])

	const handleGoBack = useCallback(() => {
		history.push('/wallet/create')
	}, [history])

	const handleMint = useCallback(async () => {
		if (loadingStatus) return

		setLoadingStatus('Processing transaction')

		try {
			await window.solana.connect()
			const connection = new web3.Connection(
				web3.clusterApiUrl('mainnet-beta'),
				'finalized'
			)

			// TODO: make this an env var
			const destinationWalletPublicKey = new web3.PublicKey(
				'EegMQQ65N6MyqYYqdG6b5MKxsQxqpAK8i2bXEh5qUP2E'
			)
			const recentBlockhash = await connection.getRecentBlockhash()

			const transaction = new web3.Transaction()
			transaction.add(
				web3.SystemProgram.transfer({
					fromPubkey: window.solana.publicKey,
					toPubkey: destinationWalletPublicKey,
					lamports: WALLRIDE_FEE,
				})
			)

			transaction.feePayer = window.solana.publicKey
			transaction.recentBlockhash = recentBlockhash.blockhash

			const { signature } = await window.solana.signAndSendTransaction(
				transaction
			)

			setLoadingStatus('Confirming transaction')
			await connection.confirmTransaction(signature, 'finalized')
			console.log('Transaction confirmed')

			await new Promise(resolve => setTimeout(resolve, 1000))

			console.log('Trying to fetch transaction')
			let blockchainTransaction
			while (!blockchainTransaction) {
				blockchainTransaction = await connection.getParsedConfirmedTransaction(
					signature
				)
			}

			setLoadingStatus('Generating Wallride')
			const dimensions = {
				depth: parseInt(wallrideDimensions.depth || 0),
				width: parseInt(wallrideDimensions.width || 0),
				height: parseInt(wallrideDimensions.height || 0),
			}
			const _ = await generateNFT(mint, signature, dimensions)
			history.push(`/wallet/${mint}`)
		} catch (e) {
			console.error('Error generating 3d model', e)
			setLoadingStatus(undefined)
		}
	}, [mint, history, loadingStatus, setLoadingStatus, wallrideDimensions])

	const handleWallrideDimensionChange = dimension => e => {
		const value = e.target.value || 0
		const parsedValue = parseInt(value, 10)
		const aspectRatio = imageDimensions.width / imageDimensions.height

		const newWallrideDimensions = { ...wallrideDimensions }
		if (dimension === 'depth') {
			newWallrideDimensions.depth = value
		} else {
			if (dimension === 'width') {
				newWallrideDimensions.width = value

				const newHeight = `${Math.round(parsedValue / aspectRatio)}`
				newWallrideDimensions.height = newHeight
			} else {
				newWallrideDimensions.height = value

				const newWidth = `${Math.round(parsedValue * aspectRatio)}`
				newWallrideDimensions.width = newWidth
			}
		}

		setWallrideDimensions(newWallrideDimensions)
	}

	const handleImageDimensions = useCallback(
		dimensions => {
			setImageDimensions(dimensions)

			let wallrideDimensions = { ...dimensions, depth: '1' }
			if (
				wallrideDimensions.width > MAX_DIMENSION ||
				wallrideDimensions.height > MAX_DIMENSION
			) {
				const aspectRatio = dimensions.width / dimensions.height
				const biggerDimension = aspectRatio > 1 ? 'width' : 'height'

				if (biggerDimension === 'width') {
					wallrideDimensions.width = MAX_DIMENSION
					wallrideDimensions.height = Math.round(
						wallrideDimensions.width / aspectRatio
					)
				} else if (biggerDimension === 'height') {
					wallrideDimensions.height = MAX_DIMENSION
					wallrideDimensions.width = Math.round(
						wallrideDimensions.height * aspectRatio
					)
				}
			}

			setWallrideDimensions(wallrideDimensions)
		},
		[setImageDimensions, setWallrideDimensions]
	)

	const collection = getNFTMetadataCollection(nftMetadataInfo)

	return (
		<div className="nftcreationpage">
			<div className="nftcreationpage-header">
				<Header
					title="2. Mint"
					leftAccessory="back"
					onLeftAccessoryClick={handleGoBack}
				/>

				{nftMetadataInfo && (
					<div className="nft-image">
						<AspectRatioImage
							maxWidth={300}
							maxHeight={300}
							imageUrl={nftMetadataInfo.image}
							imageName={nftMetadataInfo.name}
							onImageDimensions={handleImageDimensions}
						/>
					</div>
				)}
			</div>

			{nftMetadataInfo && (
				<>
					<div className="nft-info-wrapper">
						<div className="nft-title-wrapper">
							<div className="nft-title">{nftMetadataInfo.name}</div>
							{collection && (
								<div className="nft-collection">/ {collection}</div>
							)}
						</div>
					</div>

					<div className="nft-form-wrapper">
						<div className="nft-fields-wrapper">
							<TextField
								required
								right="in"
								label="Height"
								value={wallrideDimensions?.height || ''}
								onChange={handleWallrideDimensionChange('height')}
							/>

							<TextField
								required
								right="in"
								label="Width"
								value={wallrideDimensions?.width || ''}
								onChange={handleWallrideDimensionChange('width')}
							/>

							<TextField
								required
								right="in"
								label="Depth"
								value={wallrideDimensions?.depth || ''}
								onChange={handleWallrideDimensionChange('depth')}
							/>
						</div>

						<div className="nft-cost-wrapper">
							<span className="cost">Cost: .3 Solana</span>

							<span className="flat-rate">
								Flat Rate. May take up to 1 min.
							</span>
						</div>

						<div
							onClick={handleMint}
							className={classnames('mint-button', {
								'mint-button--loading': !!loadingStatus,
								'mint-button--disabled': !!loadingStatus,
							})}
						>
							{!!loadingStatus ? (
								<>
									{loadingStatus}
									<Loader
										width={30}
										height={30}
										color="white"
										type="TailSpin"
									/>
								</>
							) : (
								'Create Wallride'
							)}
						</div>
					</div>
				</>
			)}
		</div>
	)
}

export default NFTCreationPage
