import { useContext, useMemo } from 'react'
import QRCode from 'react-qr-code'
import { WalletContext } from '../../../../../contexts/wallet'

function DownloadAppStep({ onClose }) {
	const { publicKey } = useContext(WalletContext)

	const qrCodeValue = useMemo(() => {
		const walletInfo = {
			blockchain: 'SOLANA',
			walletPublicKey: publicKey,
		}

		return JSON.stringify(walletInfo)
	}, [publicKey])

	return (
		<>
			<span className="title">2. Connect</span>

			<div className="content-wrapper">
				<div className="connect-instructions">
					1. Open Wallride Viewer app.
					<br />
					2. Tap Connect & Scan QR Code.
					<br />
					3. Once connected, any Wallride NFT you create, trade or buy with a
					Wallride will show in your viewer.
				</div>

				<QRCode size={150} value={qrCodeValue} />

				<div className="next-button" onClick={onClose}>
					Done
				</div>
			</div>
		</>
	)
}

export default DownloadAppStep
