import * as web3 from '@solana/web3.js'
import * as splToken from '@solana/spl-token'
import { base58_to_binary } from 'base58-js'

import { decodeMetadata } from './metadata/metadata'
import { METADATA_PROGRAM_ID } from './metadata/ids'

export const connectPhantomWallet = async () => {
	return new Promise(async (resolve, reject) => {
		const onConnect = () => {
			window.solana.removeListener('connect', onConnect)
			return resolve()
		}

		if (window?.solana && window.solana.isPhantom) {
			try {
				window.solana.on('connect', onConnect)
				await window.solana.connect()
			} catch (e) {
				window.solana.removeListener('connect', onConnect)
				console.error('Error', e)
				return reject(e)
			}
		}
	})
}

export const fetchWalletNFTs = async walletPublicKey => {
	const connection = new web3.Connection(
		web3.clusterApiUrl('mainnet-beta'),
		'confirmed'
	)

	const publicKey = new web3.PublicKey(walletPublicKey)

	let nftsInfos = []
	try {
		const { value: tokenAccounts } =
			await connection.getParsedTokenAccountsByOwner(publicKey, {
				programId: splToken.TOKEN_PROGRAM_ID,
			})

		const nftTokenAccounts = tokenAccounts.filter(({ account }) => {
			const tokenAmount = account?.data?.parsed?.info?.tokenAmount
			return tokenAmount?.amount === '1' && tokenAmount?.decimals === 0
		})

		for (const account of nftTokenAccounts) {
			try {
				const mintAddress = account.account.data.parsed.info.mint

				if (mintAddress) {
					const pdaSeeds = [
						'metadata',
						base58_to_binary(METADATA_PROGRAM_ID),
						base58_to_binary(mintAddress),
					]

					const metadataProgramPublicKey = new web3.PublicKey(
						METADATA_PROGRAM_ID
					)

					const [pdaAddress] = await web3.PublicKey.findProgramAddress(
						pdaSeeds,
						metadataProgramPublicKey
					)

					const accountInfo = await connection.getAccountInfo(pdaAddress)

					const dataBuffer = Buffer.from(accountInfo.data)
					const deserialized = decodeMetadata(dataBuffer)

					nftsInfos.push(deserialized)
				}
			} catch (e) {
				console.error('Error fetching account', e)
				return Promise.reject(e)
			}
		}
	} catch (e) {
		console.error('Error fetching token accounts', e)
		return Promise.reject(e)
	}

	return Promise.resolve(nftsInfos)
}
