import { useState, useCallback } from 'react'

import SwitcherStep from './steps/Switcher'
import ConnectAppStep from './steps/ConnectApp'
import DownloadAppStep from './steps/DownloadApp'

function Wallride3DExpModal(props) {
	const [step, setStep] = useState('switch')

	const handleStepChange = useCallback(
		nextStep => {
			setStep(nextStep)
		},
		[setStep]
	)

	return (
		<div className="walltide3dexpmodal">
			<div className="modal-content">
				<div className="close-button" onClick={props.onClose} />

				{step === 'switch' && (
					<SwitcherStep
						{...props}
						onStepChange={handleStepChange}
						onClose={props.onClose}
					/>
				)}
				{step === 'download' && (
					<DownloadAppStep
						{...props}
						onStepChange={handleStepChange}
						onClose={props.onClose}
					/>
				)}
				{step === 'connect' && (
					<ConnectAppStep
						{...props}
						onStepChange={handleStepChange}
						onClose={props.onClose}
					/>
				)}
			</div>
		</div>
	)
}

export default Wallride3DExpModal
