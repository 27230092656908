export const getNFTMetadataCollection = nftMetadataInfo => {
	if (!nftMetadataInfo) return undefined

	const collections = nftMetadataInfo.collections
	if (collections) {
		return collections.family || collections.name
	}

	const collection = nftMetadataInfo.collection
	if (collection) {
		return collection.family || collection.name
	}

	return undefined
}
