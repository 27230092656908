import ReactGA from 'react-ga'
import { Route, Switch, BrowserRouter } from 'react-router-dom'

import HomePage from './pages/home'
import WalletPage from './pages/wallet'
import NFTCreationFlow from './pages/creation'
import WallrideViewerPage from './pages/wallride-viewer'

import { WalletContextProvider } from './contexts/wallet'

import './styles/index.scss'
import './fonts/PressStart2P-Regular.ttf'

ReactGA.initialize('G-HKJETKXDG6')
ReactGA.pageview(window.location.pathname + window.location.search)

function App() {
	return (
		<WalletContextProvider>
			<BrowserRouter>
				<Switch>
					<Route exact path="/">
						<HomePage />
					</Route>

					<Route path="/wallet/create">
						<NFTCreationFlow />
					</Route>

					<Route path="/wallet">
						<WalletPage />
					</Route>

					<Route exact path="/wallride-viewer/:wallrideId">
						<WallrideViewerPage />
					</Route>
				</Switch>
			</BrowserRouter>
		</WalletContextProvider>
	)
}

export default App
