import { useMemo } from 'react'
import QRCode from 'react-qr-code'

function TryARExpModal(props) {
	const demoGLTFFileURL = useMemo(() => {
		return `${window.location.origin}/wallride-viewer/3LTbPJmXZNigVXYHWp968JGAXq4widU1sYyxUZkD21nn`
	}, [])

	return (
		<div className="tryarexpmodal">
			<div className="modal-content">
				<div className="close-button" onClick={props.onClose} />

				<span className="title">TRY AR EXP</span>
				<span className="subtitle">View on your wall</span>

				<div className="qrcodes-wrapper">
					<div className="qrcode">
						<span className="label">iOS & Android</span>
						<QRCode size={150} value={demoGLTFFileURL} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default TryARExpModal
