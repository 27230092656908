import { useMemo, useState, useEffect, useCallback } from 'react'
import classnames from 'classnames'

import NFTCardLoading from './NFTCardLoading'
import AspectRatioImage from '../../../components/AspectRatioImage'

import { getNFTMetadataCollection } from '../../../utils/metadata/collection'

function NFTCard(props) {
	const [imageLoaded, setImageLoaded] = useState(false)
	const [nftMetadataInfo, setNFTMetadataInfo] = useState(undefined)

	// TODO: find a way to make this decently
	useEffect(() => {
		fetch(props.nft.data?.uri)
			.then(response => response.json())
			.then(data => {
				setNFTMetadataInfo(data)
			})
			.catch(e => {
				console.error('Error fetching metadata', e)
			})
	}, [props.nft.data?.uri])

	const handleClick = useCallback(() => {
		props.onClick(props.nft, nftMetadataInfo)
	}, [props, nftMetadataInfo])

	const handleImageLoad = useCallback(() => {
		setImageLoaded(true)
	}, [setImageLoaded])

	const collection = useMemo(() => {
		return getNFTMetadataCollection(nftMetadataInfo)
	}, [nftMetadataInfo])

	if (!nftMetadataInfo) return null

	return (
		<div className="nftcard" onClick={handleClick}>
			{(!nftMetadataInfo || !imageLoaded) && <NFTCardLoading />}

			{nftMetadataInfo && (
				<div
					className={classnames('nft-card-content-wrapper', {
						'nft-card-content-wrapper--hidden': !imageLoaded,
					})}
				>
					<div className="image-board">
						<div className="image-wrapper">
							<AspectRatioImage
								maxWidth={224}
								maxHeight={224}
								onLoad={handleImageLoad}
								imageUrl={nftMetadataInfo.image}
								imageName={nftMetadataInfo.name}
							/>
						</div>
					</div>

					<div className="nft-info-wrapper">
						<div className="nft-title">{nftMetadataInfo.name}</div>
						<div className="nft-collection">{collection || ''}</div>
					</div>
				</div>
			)}
		</div>
	)
}

export default NFTCard
