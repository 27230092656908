import { Route, Switch, useLocation } from 'react-router-dom'
import { useMemo, useState, useEffect, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Loader from 'react-loader-spinner'
import classnames from 'classnames'
import _ from 'lodash'

import WallrideLogo from '../../assets/WallrideLogo'

import { WalletContext } from '../../contexts/wallet'

import WallridePage from '../wallride'

import WallrideCard from './components/WallrideCard'
import WalletConnectionBadge from '../../components/WalletConnectionBadge'

import { fetchWallrides } from '../../api'
import { fetchWalletNFTs } from '../../utils/phantom'

function WalletPage() {
	const history = useHistory()
	const location = useLocation()
	const [loading, setLoading] = useState(false)
	const [walletId, setWalletId] = useState(undefined)
	const { nfts, setNFTs, wallrides, setWallrides } = useContext(WalletContext)

	useEffect(() => {
		const fetchNFTs = async () => {
			setLoading(true)
			try {
				await window.solana.connect()
				setWalletId(window.solana.publicKey.toString())
				const nftsInfos = await fetchWalletNFTs(
					window.solana.publicKey.toString()
				)
				setNFTs(nftsInfos)
				setLoading(false)
			} catch (e) {
				setLoading(false)
				console.error('Error connecting to wallet', e)
				history.push('/')
			}
		}

		fetchNFTs()
	}, [])

	useEffect(() => {
		if (!walletId) return

		const fetchWallridesList = async () => {
			try {
				const { wallrides } = await fetchWallrides(walletId)
				setWallrides(wallrides)
			} catch (e) {
				console.error('Error fetching wallrides', e)
			}
		}

		fetchWallridesList()
	}, [walletId])

	const handleAddNFT = useCallback(() => {
		history.push('/wallet/create')
	}, [history])

	const handleOpenWallride = useCallback(
		nft => {
			history.push(`/wallet/${nft.mint}`)
		},
		[history]
	)

	const handleGoHome = useCallback(() => {
		history.push('/wallet')
	}, [history])

	const wallrideNFTs = useMemo(() => {
		return _.compact(
			nfts?.map(nft => {
				const wallride = wallrides && wallrides[nft.mint]

				if (wallride) return { nft, wallride }
				return undefined
			})
		)
	}, [nfts, wallrides])

	const availableNFTsCount = useMemo(() => {
		if (!nfts) return 0
		if (!wallrides) return nfts.length

		return nfts.length - Object.keys(wallrides).length
	}, [nfts, wallrides])

	return (
		<div className="walletpage">
			<WalletConnectionBadge />
			<div className="sidebar">
				<div className="wallride-logo" onClick={handleGoHome}>
					<WallrideLogo fill="black" width={50} height={50} />
				</div>

				<div className="sidebar-content-wrapper">
					<div className="sidebar-content">
						<div className="add-button" onClick={handleAddNFT} />

						{wallrideNFTs?.map(wallrideInfo => (
							<WallrideCard
								wallrideInfo={wallrideInfo}
								onClick={handleOpenWallride}
								key={wallrideInfo?.nft?.mint}
								selected={location?.pathname?.includes(wallrideInfo?.nft?.mint)}
							/>
						))}
					</div>
				</div>
			</div>

			<div
				className={classnames('wallet-page-content', {
					'wallet-page-content--loading': loading,
				})}
			>
				{loading && (
					<Loader width={70} height={70} color="black" type="TailSpin" />
				)}
				{!loading && (
					<Switch>
						<Route exact path="/wallet/:mint">
							<WallridePage />
						</Route>

						<Route exact path="/wallet">
							<>
								{!nfts?.length ? (
									<>
										<div className="no-nfts">No NFTs in wallet :,(</div>
										<div className="empty-wallet-image" />
										<div />
									</>
								) : (
									<>
										<div className="create-nft-label-wrapper">
											<span className="create-nft-label">Create Wallride</span>
											<span className="available-nfts-label">
												/ {availableNFTsCount}{' '}
												{availableNFTsCount > 1 ? 'NFTs' : 'NFT'} Available
											</span>
										</div>
										<model-viewer
											ar
											auto-rotate
											camera-controls
											alt="Smash the + button"
											environment-image="neutral"
											ar-modes="webxr scene-viewer quick-look"
											src="https://artiseen-dev.s3.us-east-2.amazonaws.com/usdz/xlboxnODT4/ezpjS51orF/1eRaqKPoZQ.gltf"
										></model-viewer>
										<div />
									</>
								)}
							</>
						</Route>
					</Switch>
				)}
			</div>
		</div>
	)
}

export default WalletPage
