import { useMemo, useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { WalletContext } from '../../contexts/wallet'

import Header from './components/Header'
import NFTCard from './components/NFTCard'

function NFTSelectionPage() {
	const history = useHistory()
	const { nfts, wallrides } = useContext(WalletContext)

	const handleClose = useCallback(() => {
		history.push('/wallet')
	}, [history])

	const handleSelectNFT = useCallback(
		(nft, metadataInfo) => {
			history.push(`/wallet/create/${nft.mint}`)
		},
		[history]
	)
	const availableNFTs = useMemo(() => {
		const wallrideIds = wallrides ? Object.keys(wallrides) : []
		if (nfts) {
			return nfts.filter(nft => !wallrideIds.includes(nft.mint))
		}

		return undefined
	}, [nfts, wallrides])

	return (
		<div className="nftselectionpage">
			<Header
				title="1. Select NFT"
				leftAccessory="close"
				onLeftAccessoryClick={handleClose}
			/>

			<div className="nfts-list">
				{availableNFTs?.map(nft => (
					<NFTCard nft={nft} key={nft.mint} onClick={handleSelectNFT} />
				))}
			</div>
		</div>
	)
}

export default NFTSelectionPage
