import ContentLoader from 'react-content-loader'

function NFTCardLoading() {
	return (
		<ContentLoader viewBox="0 0 280 365">
			<rect x="0" y="0" width="280" height="280" />

			<rect x="30" y="293" width="220" height="25" />
			<rect x="50" y="328" width="180" height="15" />
		</ContentLoader>
	)
}

export default NFTCardLoading
