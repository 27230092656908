import { useMemo, useState, useEffect, useContext, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Loader from 'react-loader-spinner'

import Wallride3DExpModal from './components/Wallride3DExpModal'

import { WalletContext } from '../../contexts/wallet'

import { fetchWalltideFiles } from '../../api'
import { getNFTMetadataCollection } from '../../utils/metadata/collection'

function WallridePage() {
	const history = useHistory()
	const { mint } = useParams()
	const [loading, setLoading] = useState(false)
	const [wallrideFiles, setWallrideFiles] = useState(undefined)
	const [nftMetadataInfo, setNFTMetadataInfo] = useState(undefined)
	const [wallrideModalOpen, setWallrideModalOpen] = useState(false)
	const { nfts, setNFTs, wallrides, setWallrides } = useContext(WalletContext)

	useEffect(() => {
		if (!mint || !nfts) return handleGoBack()

		const nft = nfts.find(nft => nft.mint === mint)
		if (!nft) return handleGoBack()

		setLoading(true)
		fetch(nft.data?.uri)
			.then(response => response.json())
			.then(data => {
				setNFTMetadataInfo(data)
			})
			.catch(e => {
				handleGoBack()
			})
			.finally(() => {
				setLoading(false)
			})
	}, [mint])

	useEffect(() => {
		fetchWalltideFiles(mint)
			.then(files => setWallrideFiles(files))
			.catch(e => {
				console.error('Error fetching Wallride files', e)
			})
	}, [mint])

	const handleGoBack = useCallback(() => {
		history.push('/wallet')
	}, [history])

	const handleOpenWallrideModal = useCallback(() => {
		setWallrideModalOpen(true)
	}, [setWallrideModalOpen])

	const handleCloseWallrideModal = useCallback(() => {
		setWallrideModalOpen(false)
	}, [setWallrideModalOpen])

	const collection = useMemo(() => {
		return getNFTMetadataCollection(nftMetadataInfo)
	}, [nftMetadataInfo])

	return (
		<div className="wallridepage">
			{wallrideModalOpen && (
				<Wallride3DExpModal
					wallrideId={mint}
					wallrideFiles={wallrideFiles}
					onClose={handleCloseWallrideModal}
				/>
			)}
			<div className="wallride-page-content">
				{loading && (
					<Loader width={70} height={70} color="black" type="TailSpin" />
				)}

				{nftMetadataInfo && (
					<>
						<div className="wallride-info-wrapper">
							<div className="title-wrapper">
								<span className="title">{nftMetadataInfo.name}</span>
								{collection && <span className="title">/ {collection}</span>}
							</div>

							{!wallrideFiles ? (
								<img alt={nftMetadataInfo.name} src={nftMetadataInfo.image} />
							) : (
								<model-viewer
									ar
									auto-rotate
									camera-controls
									src={wallrideFiles.gltf}
									alt={nftMetadataInfo.name}
									environment-image="neutral"
									ar-modes="webxr scene-viewer quick-look"
								></model-viewer>
							)}
						</div>

						<div className="wallride-qr-code-wrapper">
							{wallrideFiles && (
								<div
									className="wallride-files-button"
									onClick={handleOpenWallrideModal}
								>
									Wallride AR Exp
								</div>
								// <div className="qr-code-wrapper">
								// 	<span className="qr-code-title">Augmented Reality Exp</span>
								// 	<QRCode value={wallrideFiles.usdz} />
								// </div>
							)}
						</div>
					</>
				)}
			</div>
		</div>
	)
}

export default WallridePage
