import { useContext, useCallback } from 'react'
import { useHistory } from 'react-router-dom'

import { WalletContext } from '../contexts/wallet'

function WalletConnectionBadge() {
	const history = useHistory()
	const { connected, publicKey } = useContext(WalletContext)

	const handleDisconnect = useCallback(() => {
		window.solana.disconnect()
		history.push('/')
	}, [history])

	return (
		<div onClick={handleDisconnect} className="wallet-connection-badge">
			<span className="wallet-connection-state">
				{connected ? 'Connected' : 'Disconnected'}
			</span>
			<span className="wallet-public-key">{publicKey}</span>
		</div>
	)
}

export default WalletConnectionBadge
