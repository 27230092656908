import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { fetchWalltideFiles } from '../../api'

export const WallrideViewerPage = () => {
	const { wallrideId } = useParams()
	const [wallrideFiles, setWallrideFiles] = useState()

	useEffect(() => {
		fetchWalltideFiles(wallrideId)
			.then(files => setWallrideFiles(files))
			.catch(e => {
				console.error('Error fetching Wallride files', e)
			})
	}, [wallrideId])

	if (!wallrideFiles) return null

	return (
		<model-viewer
			ar
			camera-controls
			ar-placement="wall"
			src={wallrideFiles.gltf}
			environment-image="neutral"
			ar-modes="webxr scene-viewer quick-look"
			style={{ width: '100%', height: '100%' }}
		/>
	)
}
