import { useState, useEffect, useCallback } from 'react'
import classnames from 'classnames'

import AspectRatioImage from '../../../components/AspectRatioImage'

function WallrideCard(props) {
	const [imageLoaded, setImageLoaded] = useState(false)
	const [nftMetadataInfo, setNFTMetadataInfo] = useState(undefined)

	// TODO: find a way to make this decent
	useEffect(() => {
		fetch(props.wallrideInfo?.nft?.data?.uri)
			.then(response => response.json())
			.then(data => {
				setNFTMetadataInfo(data)
			})
			.catch(e => {
				console.error('Error fetching metadata', e)
			})
	}, [props.wallrideInfo?.nft?.data?.uri])

	const handleClick = useCallback(() => {
		props.onClick && props.onClick(props.wallrideInfo?.nft, nftMetadataInfo)
	}, [props.wallrideInfo.nft, props.onClick, nftMetadataInfo])

	const handleImageLoad = useCallback(() => {
		setImageLoaded(true)
	}, [setImageLoaded])

	if (!nftMetadataInfo) return null

	return (
		<div
			onClick={handleClick}
			className={classnames('wallridecard', {
				'wallridecard--selected': props.selected,
			})}
		>
			{nftMetadataInfo && (
				<AspectRatioImage
					maxWidth={75}
					maxHeight={75}
					onLoad={handleImageLoad}
					imageUrl={nftMetadataInfo.image}
					imageName={nftMetadataInfo.name}
				/>
			)}
		</div>
	)
}

export default WallrideCard
