const { PublicKey } = require('@solana/web3.js')
const {
	METADATA_PROGRAM_ID,
	TOKEN_PROGRAM_ID,
	SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
	METAPLEX_ID,
	BPF_UPGRADE_LOADER_ID,
	SYSTEM,
	MEMO_ID,
	VAULT_ID,
	AUCTION_ID,
	toPublicKey,
} = require('./ids')

export const findProgramAddress = async (seeds, programId) => {
	const key =
		'pda-' +
		seeds.reduce((agg, item) => agg + item.toString('hex'), '') +
		programId.toString()
	const cached = localStorage.getItem(key)
	if (cached) {
		const value = JSON.parse(cached)

		return [value.key, parseInt(value.nonce)]
	}

	const result = await PublicKey.findProgramAddress(seeds, programId)

	try {
		localStorage.setItem(
			key,
			JSON.stringify({
				key: result[0].toBase58(),
				nonce: result[1],
			})
		)
	} catch {
		// ignore
	}

	return [result[0].toBase58(), result[1]]
}

export const getStoreID = async storeOwnerAddress => {
	if (!storeOwnerAddress) {
		return undefined
	}

	const programs = await findProgramAddress(
		[
			Buffer.from('metaplex'),
			toPublicKey(METAPLEX_ID).toBuffer(),
			toPublicKey(storeOwnerAddress).toBuffer(),
		],
		toPublicKey(METAPLEX_ID)
	)
	const storeAddress = programs[0]

	return storeAddress
}

export const setProgramIds = async store => {
	STORE = store ? toPublicKey(store) : undefined
}

let STORE

export const programIds = () => {
	return {
		token: TOKEN_PROGRAM_ID,
		associatedToken: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
		bpf_upgrade_loader: BPF_UPGRADE_LOADER_ID,
		system: SYSTEM,
		metadata: METADATA_PROGRAM_ID,
		memo: MEMO_ID,
		vault: VAULT_ID,
		auction: AUCTION_ID,
		metaplex: METAPLEX_ID,
		store: STORE,
	}
}
